/*
 * @Author: Mr_Wei
 * @Description: 配置拦截器
 * @Date: 09:51 2019/07/01
 * @Param: http.js
 * @return:
 **/ 

import axios from "axios";
import qs from 'qs';
// import { Message } from 'element-ui'
import router from '../router/index.js';
// import { encrypt } from '@/assets/js/aes.js';


const server = axios.create({
    timeout: 10000  //ms
})

// 请求拦截
server.interceptors.request.use(config => {
    if(localStorage.getItem('accesstoken')){
        // "Authorization": 'bearer ' + localStorage.getItem('accesstoken'),
        config.headers['Authorization'] = 'bearer ' + localStorage.getItem('accesstoken');
    }
    // aes 加密
    // switch(config.method){
    //     case 'get':
    //         let params = encrypt(JSON.stringify(config.params));
    //         config.params = {
    //             queryData: params
    //         };
    //     break;
    //     case 'post':
    //         let data = encrypt(JSON.stringify(config.data));
    //         config.data = {
    //             queryData: data
    //         };
    //         config.data = qs.stringify(config.data,{arrayFormat: 'repeat', allowDots: true})
    //     break;
    // }
    config.data = qs.stringify(config.data,{arrayFormat: 'repeat', allowDots: true})
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截
server.interceptors.response.use(response => {
    if (response.data.statuscode === 200) {
      return Promise.resolve(response.data.data);
    }
    if (response.data.statuscode === 401) {
        localStorage.removeItem("accesstoken");
        // Message({
        //     type: 'error',
        //     message: response.data.message,
        //     duration: 1500
        // })
        setTimeout(() => {
            router.push('/login')
        }, 1400)
        return
    }
    return Promise.reject(response.data)
}, error => {
    return Promise.reject(error)
})

export default server;